
import { defineComponent } from '@vue/composition-api'
import { mapMutations } from 'vuex'

export default defineComponent({
  name: 'CarEnginesList',
  components: {
    CreateEditCarEngine: () => import('@/components/molecules/dialog/createEditCarEngine.vue'),
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 5,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    carModel: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {
      createEditCarEngineDialog: false,
      carEngineEdit: {},
    }
  },
  computed: {
    headersCarEngines (): Array<any> {
      const headers: Array<any> = [{ value: 'engine', text: this.$t('car_model.form.car_engine.engine') }]
      if (!this.readonly) {
        headers.push({
          text: this.$t('form.actions'),
          value: 'actions',
          align: 'center',
          sortable: false,
        })
      }

      return headers
    },
  },
  methods: {
    ...mapMutations('carModelForm', {
      addCarEngineForCarModel: 'addCarEngine',
      replaceCarEngineForCarModel: 'replaceCarEngine',
    }),
  },
})
